import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import FolderIcon from "@material-ui/icons/Folder"
import RestoreIcon from "@material-ui/icons/Restore"
import FavoriteIcon from "@material-ui/icons/Favorite"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import KitchenIcon from "@material-ui/icons/Kitchen"
import LocalDiningIcon from "@material-ui/icons/LocalDining"
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import styled from "styled-components"

import { colors } from "../../theme"
import { NavLink } from "../shared/link"
import { NavMenuContext } from "../../context/nav-menu"

const BottomNavContainer = styled(BottomNavigation)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: visible;
  z-index: 99;
  background-color: ${colors.tallyGrey};
  height: auto;
`

export const MobileNavMenu = () => {
  const {
    navMenuState: { mobileNavSelection },
    navMenuDispatch,
  } = useContext(NavMenuContext)

  useEffect(() => {
    navMenuDispatch({
      type: "UPDATE_MOBILE_NAV_SELECTION",
      payload: trimLocationUrl(window.location.pathname),
    })
  }, [])

  const trimLocationUrl = path => {
    // two cases in each to handle clicks and refreshs
    switch (path) {
      case "/gallery":
      case "/gallery/":
        return "gallery"
      case "/catering":
      case "/catering/":
        return "catering"
      // case "/location":
      // case "/location/":
      //   return "location"
      default:
        return "menu"
    }
  }

  const handleChange = (event, newValue) => {
    navMenuDispatch({
      type: "UPDATE_MOBILE_NAV_SELECTION",
      payload: newValue,
    })

    switch (newValue) {
      case "menu":
        navigate("/")
        break
      case "gallery":
        navigate("/gallery")
        break
      case "catering":
        navigate("/catering")
        break
      // case "location":
      //   navigate("/location")
      //   break
    }
  }

  return (
    <BottomNavContainer value={mobileNavSelection} onChange={handleChange}>
      <BottomNavigationAction
        label="menu"
        value="menu"
        icon={<KitchenIcon />}
      />
      <BottomNavigationAction
        label="gallery"
        value="gallery"
        icon={<PhotoLibraryIcon />}
      />
      <BottomNavigationAction
        label="catering"
        value="catering"
        icon={<LocalDiningIcon />}
      />
      {/* <BottomNavigationAction
        label="location"
        value="location"
        icon={<LocationOnIcon />}
      /> */}
    </BottomNavContainer>
  )
}
