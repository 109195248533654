import styled from "styled-components"
import posed from "react-pose"

const ImgContainer = posed.img({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.15,
  },
})

export const AppLogo = styled(ImgContainer)`
  margin: 12px 0 0 10px;
  height: 120px;
  cursor: pointer;
`
