import Button from "@material-ui/core/Button"
import styled from "styled-components"

import { colors, weight } from "../../../theme"

export const Btn = styled(Button)`
  color: ${colors.tallyBlack};
  font-weight: ${weight.medium};
  background-color: ${colors.tallyYellow};

  &:hover {
    background-color: ${colors.tallyGrey};
    color: ${colors.tallyYellow};
  }
`
