import React, { useContext, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import LabelIcon from "@material-ui/icons/Label"
import KitchenIcon from "@material-ui/icons/Kitchen"
import LocalDiningIcon from "@material-ui/icons/LocalDining"
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"

import { NavMenuContext } from "../../context/nav-menu"
import { NavLink } from "../shared/link"
import { colors, weight } from "../../theme"
import Drawer from "@material-ui/core/Drawer"

const useStyles = makeStyles({
  fullList: {
    width: "auto",
    color: colors.tallyYellowDark,
    fontWeight: weight.medium,
    backgroundColor: colors.tallyGrey,
  },
})

const fullList = () => {
  const {
    navMenuState: { isMenuOpen },
    navMenuDispatch,
  } = useContext(NavMenuContext)
  const classes = useStyles()

  return (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() =>
        navMenuDispatch({
          type: "UPDATE_IS_MENU_OPEN",
          payload: !isMenuOpen,
        })
      }
      onKeyDown={() =>
        navMenuDispatch({
          type: "UPDATE_IS_MENU_OPEN",
          payload: !isMenuOpen,
        })
      }
    >
      <List>
        <ListItem button key="menu">
          <ListItemIcon>
            <KitchenIcon />
          </ListItemIcon>
          <NavLink to="/">Menu</NavLink>
        </ListItem>
        {/* <ListItem button key="gallery">
          <ListItemIcon>
            <PhotoLibraryIcon />
          </ListItemIcon>
          <NavLink to="/gallery">Gallery</NavLink>
        </ListItem> */}
        <ListItem button key="catering">
          <ListItemIcon>
            <LocalDiningIcon />
          </ListItemIcon>
          <NavLink to="/catering">Catering</NavLink>
        </ListItem>
        <ListItem button key="about">
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <NavLink to="/about">About Us</NavLink>
        </ListItem>
      </List>
      {/* <Divider />
      <List>
        <ListItem button key="location">
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <NavLink to="/location">Location</NavLink>
        </ListItem>
      </List> */}
    </div>
  )
}

export const NavMenu = () => {
  const {
    navMenuState: { isMenuOpen },
    navMenuDispatch,
  } = useContext(NavMenuContext)

  useEffect(() => {}, [isMenuOpen])

  return (
    <Drawer
      anchor="bottom"
      open={isMenuOpen}
      onClose={() =>
        navMenuDispatch({
          type: "UPDATE_IS_MENU_OPEN",
          payload: !isMenuOpen,
        })
      }
    >
      {fullList()}
    </Drawer>
  )
}
