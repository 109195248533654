import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import { colors } from "../../../theme"

export const Card = styled(Paper)`
  color: ${({ color }) => color};
  padding: 16px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  text-align: center;
  min-width: 255px;
  height: 425px;
  background: ${colors.tallyGrey};
`
