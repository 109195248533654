import React from "react"
import TextField from "@material-ui/core/TextField"
import styled from "styled-components"

import { colors } from "../../../theme"

const StyledTextField = styled(TextField)`
  .MuiFormLabel-root,
  .MuiInput-input {
    color: ${colors.tallyYellow};
  }
`

export default ({
  input: { name, value, onChange, ...restInput },
  extraInputProps = {},
  meta,
  ...rest
}) => {
  return (
    <StyledTextField
      {...rest}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      onChange={onChange}
      value={value}
      name={name}
      InputProps={{
        ...restInput,
        ...extraInputProps,
      }}
    />
  )
}
