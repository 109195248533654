import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { AppLogo } from "../logo"
import { Fab } from "../../fab"
import TallyMac from "../../../../static/assets/macShack.svg"
import { NavMenu } from "../../nav-menu"
import { MobileNavMenu } from "../../mobile-nav-menu"
import { NavLink } from "../link"
import { useMobileDetection } from "../../../hooks/useMobileDetection"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Navigation = () => {
  const { isUsingMobile } = useMobileDetection()

  return (
    <Container>
      <AppLogo src={TallyMac} alt="logo" onClick={() => navigate("/")} />
      {isUsingMobile && (
        <>
          <Fab />
          <NavMenu />
        </>
      )}
      {!isUsingMobile && (
        <div style={{ marginTop: "1rem" }}>
          <NavLink to="/" useLargeMargin>
            Menu
          </NavLink>
          {/* <NavLink to="/gallery" useLargeMargin>
            Gallery
          </NavLink> */}
          <NavLink to="/catering" useLargeMargin>
            Catering
          </NavLink>
          <NavLink to="/about" useLargeMargin>
            About Us
          </NavLink>
        </div>
      )}
    </Container>
  )
}
