import styled from "styled-components"
import { KeyboardTimePicker } from "@material-ui/pickers"

import { colors } from "../../../theme"

export const TimePicker = styled(KeyboardTimePicker)`
  .MuiFormLabel-root,
  .MuiInputBase-input,
  .MuiSvgIcon-root {
    color: ${colors.tallyYellow};
  }
`
