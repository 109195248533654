import React, { useState, useEffect } from "react"
import axios from "axios"
import { makeStyles } from "@material-ui/core/styles"
import DateFnsUtils from "@date-io/date-fns"
import { isFuture, isValid, format } from "date-fns"
import { Form, Field } from "react-final-form"
import Grid from "@material-ui/core/Grid"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import styled from "styled-components"
import LinearProgress from "@material-ui/core/LinearProgress"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import CateringTemplate from "../templates/cateringTemplate"
import InputField from "../components/shared/input-field"
import { NavMenuProvider } from "../context/nav-menu"
import { Navigation } from "../components/shared/navigation"
import { MacBackGround } from "../components/shared/background"
import { Card } from "../components/shared/card"
import { ErrorMsg } from "../components/shared/error-message"
import { DatePicker } from "../components/shared/date-picker"
import { TimePicker } from "../components/shared/time-picker"
import { useMobileDetection } from "../hooks/useMobileDetection"
import { Btn } from "../components/shared/button"
import { colors } from "../theme"
import { CenteredContent } from "../components/shared/centered-content"

export const Title = styled.h1`
  font-family: "Pacifico", cursive;
  font-size: 2.3rem;
  color: ${colors.tallyWhite};
  text-shadow: 3px 4px 15px #000;
  margin-bottom: 2px;
`

export const Row = styled.div`
  display: flex
  flex-direction: ${({ isUsingMobile }) => (isUsingMobile ? "column" : "row")};
  align-items: ${({ isUsingMobile }) => (isUsingMobile ? "center" : undefined)};
  justify-content: center;
`

export const SuccessMessage = styled.p`
  font-weight: 500;
  font-size: 1.2em;
  color: #fff;
  text-shadow: 3px 4px 5px #000;
`

const useCateringStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export const useCateringForm = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [hasValidDateAndTime, setHasValidDateAndTime] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!isValid(selectedDate)) {
      setHasValidDateAndTime(false)
      setErrorMsg("Valid date required")
    } else if (!isFuture(selectedDate)) {
      setHasValidDateAndTime(false)
      setErrorMsg("Only future dates allowed")
    } else {
      setHasValidDateAndTime(true)
      setErrorMsg("")
    }
  }, [selectedDate, errorMsg])

  const classes = useCateringStyles()

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const cateringValidation = values => {
    let errors = {}

    if (!values.name) {
      errors.name = "Name required"
    }

    if (!values.phone) {
      errors.phone = "Phone number required"
    }

    if (!values.email) {
      errors.email = "Contact Email required"
    }

    if (!values.eventLocation) {
      errors.eventLocation = "Event Location required"
    }

    return errors
  }

  const handleSubmission = async values => {
    // google analytics tracker for catering request
    trackCustomEvent({
      category: "Catering Submission",
      action: "Click",
      label: "Catering Request",
    })

    const formattedDate = format(selectedDate, "MMM, dd yyyy p")
    setSubmitting(true)
    axios({
      method: "POST",
      url: "https://formspree.io/mnqjgpal",
      data: { selectedDate: formattedDate, ...values },
    })
      .then(response => {
        setSubmitting(false)
        handleServerResponse(true, "Thanks!")
      })
      .catch(error => {
        setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }

  // only show date error if the rest of the form is complete
  const shouldShowError = ({ name, phone, address }) =>
    name && phone && address && errorMsg

  return {
    selectedDate,
    setSelectedDate,
    hasValidDateAndTime,
    setHasValidDateAndTime,
    errorMsg,
    setErrorMsg,
    serverState,
    setServerState,
    submitting,
    setSubmitting,
    classes,
    handleServerResponse,
    cateringValidation,
    handleSubmission,
    shouldShowError,
  }
}

export default () => {
  const {
    selectedDate,
    setSelectedDate,
    hasValidDateAndTime,
    errorMsg,
    serverState,
    submitting,
    classes,
    cateringValidation,
    handleSubmission,
    shouldShowError,
  } = useCateringForm()

  const { isUsingMobile } = useMobileDetection()

  return (
    <NavMenuProvider>
      <MacBackGround overrideHeight>
        <Navigation />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            backgroundColor={colors.tallyGrey}
            style={{ width: "85%", height: "auto", marginTop: "1rem" }}
          >
            <Title>Book Tally Mac Shack!</Title>
            <Form
              onSubmit={handleSubmission}
              validate={cateringValidation}
              render={formProps => (
                <form onSubmit={formProps.handleSubmit}>
                  <input
                    type="text"
                    name="_gotcha"
                    style={{ display: "none" }}
                  />
                  <Row isUsingMobile={isUsingMobile}>
                    <Field
                      data-testid="catering-customer-name"
                      id="catering-customer-name"
                      name="name"
                      label="Name"
                      className={classes.textField}
                      style={{ minWidth: "223px" }}
                      margin="normal"
                      component={InputField}
                    />
                    <Field
                      data-testid="catering-customer-phone"
                      id="catering-customer-phone"
                      name="phone"
                      label="Phone"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                    <Field
                      data-testid="catering-customer-email"
                      id="catering-customer-email"
                      name="email"
                      label="Email"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                  </Row>
                  <Row isUsingMobile={isUsingMobile}>
                    <Field
                      data-testid="catering-customer-event-location"
                      id="catering-customer-event-location"
                      name="eventLocation"
                      label="Event Location"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="date-picker-inline"
                        disableToolbar
                        style={{
                          paddingRight: isUsingMobile ? undefined : ".5rem",
                        }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Date"
                        value={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <TimePicker
                        id="time-picker"
                        margin="normal"
                        style={{
                          paddingLeft: isUsingMobile ? undefined : ".5rem",
                        }}
                        label="Time picker"
                        value={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Row>
                  <Row>
                    <Field
                      data-testid="catering-customer-notes"
                      id="catering-customer-notes"
                      name="notes"
                      label="Notes"
                      style={{ width: "85%" }}
                      className={classes.textField}
                      placeholder="Special request and expected guest count"
                      variant="outlined"
                      component={InputField}
                    />
                  </Row>
                  {shouldShowError(formProps.values) && (
                    <ErrorMsg>{errorMsg}</ErrorMsg>
                  )}
                  {serverState && !serverState.ok && (
                    <ErrorMsg>
                      Unable to submit request, please try again
                    </ErrorMsg>
                  )}
                  {serverState && serverState.ok && (
                    <SuccessMessage>
                      Thank you for your submission! We'll be in touch shortly
                    </SuccessMessage>
                  )}
                  <Btn
                    disabled={
                      formProps.hasValidationErrors ||
                      formProps.submitting ||
                      !hasValidDateAndTime
                    }
                    style={{ margin: "1rem 0" }}
                    type="submit"
                  >
                    Submit Request
                  </Btn>
                  {submitting && <LinearProgress />}
                </form>
              )}
            />
            <CateringTemplate />
          </Card>
        </div>
      </MacBackGround>
    </NavMenuProvider>
  )
}
