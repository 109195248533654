import React, { useEffect } from "react"
import styled from "styled-components"

import Result from "../../../../static/assets/result.svg"
import { Footer } from "../footer"
import { colors } from "../../../theme"

export const MacNCheeseBackGround = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ::after {
    content: "";
    background: url(${Result});
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`

const BackGroundContainer = styled.div`
  position: relative;
  background-color: ${colors.tallyBackground}; /* fallback for old browsers */
  /* background: #00b09b;  */
  background: ${`-webkit-linear-gradient(
    to top,
    ${colors.tallyBackground},
    ${colors.tallyGreyInset}
  )`}; /* Chrome 10-25, Safari 5.1-6 */
  background: ${`linear-gradient(
    to top,
    ${colors.tallyBackground},
    ${colors.tallyGreyInset}
  )`}; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
`

const Content = styled.div`
  margin-bottom: 2.5rem;
`

export const BackgroundColor = styled.div`
  background-color: ${({ color }) => color};
`

export const MacBackGround = props => (
  <>
    <BackGroundContainer
      style={{
        height: props.overrideHeight ? "100%" : "auto",
      }}
    >
      <Content>{props.children}</Content>
    </BackGroundContainer>
    <Footer />
  </>
)
