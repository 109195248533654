import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { colors, weight } from "../../../theme"

const StyledNavLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${({ useLargeFont }) => (useLargeFont ? "1.7rem" : "1.2rem")};
  margin: ${({ useMargin, useLargeMargin }) =>
    useMargin ? "0 .5rem" : useLargeMargin ? "0 1rem" : undefined};
  color: ${({ useDarkText }) => (useDarkText ? colors.tallyBlack : "#fff")};
  font-weight: ${weight.heavy};
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
`
export const NavLink = ({
  to,
  children,
  useMargin = false,
  useDarkText = false,
  useLargeFont = false,
  useLargeMargin = false,
}) => (
  <StyledNavLink
    to={to}
    activeStyle={{
      borderBottomStyle: "solid",
      borderColor: colors.tallyYellow,
    }}
    useMargin={useMargin}
    useDarkText={useDarkText}
    useLargeFont={useLargeFont}
    useLargeMargin={useLargeMargin}
  >
    {children}
  </StyledNavLink>
)
