import styled, { css } from "styled-components"
import { colors } from "../../../theme"

export const FabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FabSpan = css`
  span {
    background-color: ${colors.tallyYellow};
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 4px;
    transition-duration: 500ms;
  }

  span:nth-child(1) {
    left: 0px;
    ${({ isMenuOpen }) =>
      isMenuOpen
        ? `
        transform: rotate(45deg);
        top: 13px;
        `
        : `top: 0px;`}
  }

  span:nth-child(2) {
    left: 0px;
    ${({ isMenuOpen }) =>
      isMenuOpen
        ? `
        opacity: 0;
        `
        : `
        top: 13px;
        opacity: 1;
        `}
  }

  span:nth-child(3) {
    ${({ isMenuOpen }) =>
      isMenuOpen
        ? `
        transform: rotate(-45deg);
        top: 13px;
        `
        : `
        bottom: 0px;
        left: 0px;
        `}
  }

  :hover span:nth-child(1) {
    ${({ isMenuOpen }) =>
      !isMenuOpen ? `transform: rotate(-3deg) scaleY(1.1);` : undefined}
  }

  :hover span:nth-child(2) {
    ${({ isMenuOpen }) =>
      !isMenuOpen ? `transform: rotate(3deg) scaleY(1.1);` : undefined}
  }

  :hover span:nth-child(3) {
    ${({ isMenuOpen }) =>
      !isMenuOpen ? `transform: rotate(-4deg) scaleY(1.1);` : undefined}
  }
`
export const FabIcon = styled.div`
  width: 35px;
  height: 30px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;

  ${FabSpan}
`
