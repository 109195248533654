import React, { useContext, useEffect } from "react"

import { NavMenuContext } from "../../context/nav-menu"
import { FabIcon, FabContainer } from "./styles"

export const Fab = () => {
  const {
    navMenuState: { isMenuOpen },
    navMenuDispatch,
  } = useContext(NavMenuContext)

  useEffect(() => {}, [isMenuOpen])

  return (
    <FabContainer>
      <FabIcon
        onClick={() =>
          navMenuDispatch({
            type: "UPDATE_IS_MENU_OPEN",
            payload: !isMenuOpen,
          })
        }
        isMenuOpen={isMenuOpen}
      >
        <span></span>
        <span></span>
        <span></span>
      </FabIcon>
    </FabContainer>
  )
}
