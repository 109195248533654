import React from "react"
import { graphql, StaticQuery } from "gatsby"

import styled from "styled-components"
import { weight, colors } from "../theme"

export const InfoSection = styled.p`
  font-weight: ${weight.light};
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  color: #9e9782;
`

const CateringTemplate = ({ data }) => {
  const {
    markdownRemark: { html },
  } = data // data.markdownRemark.html holds our content for this page

  return <InfoSection dangerouslySetInnerHTML={{ __html: html }} />
}

export default () => (
  <StaticQuery
    query={graphql`
      query CateringTemplate {
        markdownRemark(
          frontmatter: { path: { eq: "/catering/main-content" } }
        ) {
          html
        }
      }
    `}
    render={data => <CateringTemplate data={data} />}
  />
)
