import React, { createContext, useReducer } from "react"
import { Helmet } from "react-helmet"

const NavMenuContext = createContext({})

const navMenuInitialState = {
  isMenuOpen: false,
  mobileNavSelection: "",
}

const navMenuReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_IS_MENU_OPEN":
      return { ...state, isMenuOpen: action.payload }
    case "UPDATE_MOBILE_NAV_SELECTION":
      return { ...state, mobileNavSelection: action.payload }
    default:
      return state
  }
}

const NavMenuProvider = props => {
  const [navMenuState, navMenuDispatch] = useReducer(
    navMenuReducer,
    navMenuInitialState
  )

  const localContext = { navMenuState, navMenuDispatch }

  return (
    <NavMenuContext.Provider value={localContext}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Pacifico&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {props.children}
    </NavMenuContext.Provider>
  )
}

export { NavMenuContext, NavMenuProvider }
