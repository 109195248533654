import React from "react"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"
import SvgIcon from "@material-ui/core/SvgIcon"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { colors, weight } from "../../../theme"
import { AppLogo } from "../logo"
import WhiteTallyMac from "../../../../static/assets/whiteMacShack.svg"
import { NavLink } from "../link"
import { useMobileDetection } from "../../../hooks/useMobileDetection"

const StyledFooter = styled.footer`
  position: absolute;
  background-color: ${colors.tallyLightGrey}
  bottom: 0;
  width: 100%;
  height: 2.5rem;
`

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CopyRight = styled.span`
  font-size: 1.3em;
  color: #ffffff1a;
`

const LegalLinks = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  color: #ffffff9c;
  font-weight: ${weight.light};
  font-family: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
`

export const Footer = () => {
  const { isUsingMobile } = useMobileDetection()

  return (
    <StyledFooter>
      <ContentRow
        style={{
          flexDirection: isUsingMobile ? "column" : "row",
          margin: isUsingMobile ? "0 2rem" : "0 4rem",
        }}
      >
        <AppLogo
          src={WhiteTallyMac}
          style={{
            height: isUsingMobile ? "12rem" : undefined,
            marginBottom: isUsingMobile ? ".5rem" : undefined,
          }}
          alt="logo"
          onClick={() => navigate("/")}
        />
        <div>
          {/* <NavLink to="/" useMargin>
            Menu
          </NavLink>
          <NavLink to="/catering" useMargin>
            Catering
          </NavLink>
          <NavLink to="/about" useMargin>
            About Us
          </NavLink> */}

          {/* <NavLink to="/gallery" useMargin>
            Gallery
          </NavLink> */}
          {/* <NavLink to="/location" useMargin>
            Location
          </NavLink> */}
        </div>
        <div>
          <IconButton aria-label="facebook profile">
            <OutboundLink
              href="https://www.facebook.com/TallyMacShack/"
              target="_blank"
            >
              <SvgIcon>
                <svg
                  className="svg-inline--fa fa-facebook-f fa-w-9"
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 264 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="#FFF"
                    d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                  ></path>
                </svg>
              </SvgIcon>
            </OutboundLink>
          </IconButton>
          <IconButton aria-label="twitter link">
            <OutboundLink
              href="https://twitter.com/tallymacshack?lang=en"
              target="_blank"
            >
              <SvgIcon>
                <svg
                  className="svg-inline--fa fa-twitter fa-w-16"
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="twitter"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="#FFF"
                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                  ></path>
                </svg>
              </SvgIcon>
            </OutboundLink>
          </IconButton>
          <IconButton aria-label="instagram link">
            <OutboundLink
              href="https://www.instagram.com/tallymacshack/?hl=en"
              target="_blank"
            >
              <SvgIcon>
                <svg
                  class="svg-inline--fa fa-instagram fa-w-14"
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="instagram"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="#FFF"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </SvgIcon>
            </OutboundLink>
          </IconButton>
        </div>
      </ContentRow>
      <hr
        style={{
          borderColor: "#ffffff1a",
          margin: isUsingMobile ? "1rem 2rem" : "1rem 4rem",
        }}
      />
      <ContentRow
        style={{
          margin: isUsingMobile ? "0 2rem" : "0 4rem",
          flexDirection: isUsingMobile ? "column-reverse" : "row",
          padding: "1rem 0",
        }}
      >
        <CopyRight>© 2020 Tally Mac Shack, All Rights Reserved.</CopyRight>
        <div style={{ paddingBottom: isUsingMobile ? ".5rem" : undefined }}>
          <LegalLinks
            href="https://app.termly.io/document/privacy-policy/3832aa5e-4673-4092-9d5d-52a210e5f1e9"
            target="_blank"
          >
            privacy policy
          </LegalLinks>
          {/* terms and conditions not needed yet since there's no user accounts or services or products being sold on the site */}
          {/* <LegalLinks href="/">terms and conditions</LegalLinks> */}
        </div>
      </ContentRow>
    </StyledFooter>
  )
}
